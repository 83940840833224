<template>
	<div class="c-block-list-accordion-shared-items">
		<BlockList :block-list="elements" />
	</div>
</template>

<script>
import BlockList from '~/components/main/BlockList';

export default {
	name: 'BlockListAccordionSharedItemsCustom',

	components: {
		BlockList,
	},

	inheritAttrs: false,

	props: {
		title: {
			type: String,
			required: false,
		},
		id: {
			type: String,
			required: false,
		},
		elements: {
			type: Array,
			default: () => [],
		},
	},
};
</script>
